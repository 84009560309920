import {StoresWidgetID} from '@wix/wixstores-client-core/dist/es/src/constants';
import {
  BooleanStyleParam,
  ColorStyleParam,
  ComponentClientSpecMapEntry,
  EditorSDK,
  FontStyleParam,
  NumericStyleParam,
  StyleParam,
} from '@wix/platform-editor-sdk';
import {ThankYouPageStyleParams} from '@wix/wixstores-client-core/dist/es/src/style-params';
import {EcomStyleParams} from '@wix/ecom-platform-sdk/dist/es/src/types';

export class StyleParams {
  constructor(private readonly sdk: EditorSDK, private readonly applicationId: number) {}

  public async setCheckoutStyleParam(type: StyleParam['type'], key: string, param: {value: any}) {
    const allComponents = await this.getAllApplicationComponents();
    const checkoutComponent = allComponents.find((comp) => comp.widgetId === StoresWidgetID.CHECKOUT);
    if (!checkoutComponent) {
      return;
    }
    const compRef = await this.sdk.document.components.getById('', {id: checkoutComponent.id});

    await this.sdk.tpa.setStyleParams('', {
      compRef,
      styleParams: [{type, key, param}],
    });
  }

  public async setThankYouPageStyleParams(styleParams?: EcomStyleParams) {
    if (!styleParams?.thankYouPage) {
      return;
    }

    const allComponents = await this.getAllApplicationComponents();
    const thankYouPageComponent = this.getComponent(allComponents, StoresWidgetID.THANK_YOU_PAGE);
    if (!thankYouPageComponent) {
      return;
    }
    const compRef = await this.sdk.document.components.getById('', {id: thankYouPageComponent.id});
    const colorStyleParams = mapColorParams(styleParams);
    const booleanStyleParams = mapBooleanParams(styleParams);
    const fontStyleParams = mapFontParams(styleParams);
    const numberStyleParams = mapNumberParams(styleParams);
    const combinedStyleParams = [...colorStyleParams, ...booleanStyleParams, ...fontStyleParams, ...numberStyleParams];

    if (!combinedStyleParams.length) {
      return;
    }

    await this.sdk.tpa.setStyleParams('', {
      compRef,
      styleParams: combinedStyleParams,
    });
  }

  private getAllApplicationComponents(): Promise<ComponentClientSpecMapEntry[]> {
    return this.sdk.document.tpa.app.getAllCompsByApplicationId('', this.applicationId);
  }

  private getComponent(allComponents: ComponentClientSpecMapEntry[], componentId: string) {
    return allComponents.find((comp) => comp.widgetId === componentId);
  }
}

function getColorStyleParams(key: string, param: ColorStyleParam['param']): ColorStyleParam {
  return getStyleParams<ColorStyleParam>('color', key, param);
}

function getBooleanStyleParams(key: string, param: BooleanStyleParam['param']): BooleanStyleParam {
  return getStyleParams<BooleanStyleParam>('boolean', key, param);
}

function getFontStyleParams(key: string, param: FontStyleParam['param']): FontStyleParam {
  return getStyleParams<FontStyleParam>('font', key, param);
}

function getNumberStyleParams(key: string, param: NumericStyleParam['param']): NumericStyleParam {
  return getStyleParams<NumericStyleParam>('number', key, param);
}

function getStyleParams<T1 extends StyleParam>(type: T1['type'], key: string, param: T1['param']) {
  return {
    type,
    key,
    param,
  };
}

export function mapColorParams(styleParams: EcomStyleParams): ColorStyleParam[] {
  const styleParamsMap = styleParams.thankYouPage?.colorParams;
  if (!styleParamsMap) {
    return [];
  }
  return Object.entries(styleParamsMap).map(([key, value]) =>
    getColorStyleParams(
      ThankYouPageStyleParams.ColorParamNames[key as keyof typeof ThankYouPageStyleParams['ColorParamNames']],
      value
    )
  );
}

export function mapBooleanParams(styleParams: EcomStyleParams): BooleanStyleParam[] {
  const styleParamsMap = styleParams.thankYouPage?.booleanParams;
  if (!styleParamsMap) {
    return [];
  }
  return Object.entries(styleParamsMap).map(([key, value]) =>
    getBooleanStyleParams(
      ThankYouPageStyleParams.BooleanParamNames[key as keyof typeof ThankYouPageStyleParams['BooleanParamNames']],
      value
    )
  );
}

export function mapFontParams(styleParams: EcomStyleParams): FontStyleParam[] {
  const styleParamsMap = styleParams.thankYouPage?.fontParams;
  if (!styleParamsMap) {
    return [];
  }
  return Object.entries(styleParamsMap).map(([key, value]) =>
    getFontStyleParams(
      ThankYouPageStyleParams.FontParamNames[key as keyof typeof ThankYouPageStyleParams['FontParamNames']],
      value
    )
  );
}

export function mapNumberParams(styleParams: EcomStyleParams): NumericStyleParam[] {
  const styleParamsMap = styleParams.thankYouPage?.numberParams;
  if (!styleParamsMap) {
    return [];
  }
  return Object.entries(styleParamsMap).map(([key, value]) =>
    getNumberStyleParams(
      ThankYouPageStyleParams.NumberParamNames[key as keyof typeof ThankYouPageStyleParams['NumberParamNames']],
      value
    )
  );
}
