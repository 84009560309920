import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {ecomAppDefID} from '../constants';
import {EditorSDK, PageRef} from '@wix/platform-editor-sdk';

export async function adiMissingPagesMigration(sdk: EditorSDK) {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll('');
  const storesPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
  const existsMandatoryPages = await Promise.all(
    storesPages.map((page) => {
      const pageRef: PageRef = {id: page.id, type: 'DESKTOP'};
      return sdk.pages.data.get('', {
        pageRef,
      });
    })
  ).then((pages: any) => {
    return pages.map((pageData) => pageData.tpaPageId);
  });

  const promises = [PageMap.CART, PageMap.PRODUCT, PageMap.THANKYOU].map((mandatoryPage) => {
    if (!existsMandatoryPages.includes(mandatoryPage)) {
      return sdk.tpa.add.component('', {
        appDefinitionId: ecomAppDefID,
        componentType: 'PAGE',
        page: {
          pageId: mandatoryPage,
        },
      });
    } else {
      return Promise.resolve();
    }
  });

  await Promise.all<any>(promises);
}
