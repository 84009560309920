import {Logger} from '@wix/bi-logger-ec-sf';
import {AppApiModel} from '@wix/wixstores-client-core/dist/src/types/app-api-model';
import {isMembersAreaInstalled, maybeInstallMembersArea} from '@wix/members-area-integration-kit';
import {doTransaction} from '../transaction';
import {delay} from '@wix/wixstores-client-core/dist/src/utils/delay';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';
import {ecomAppDefID} from '../constants';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {STORES_APP_DEF_ID} from '../../stores-editor-script/constants';
import {DependantApps} from '../services/DependantApps';
import {StyleParams} from '../services/StyleParams';
import {EditorSDK, StyleParam} from '@wix/platform-editor-sdk';

export function createAppApi({
  sdk,
  dependantApps,
  styleParams,
  t,
  biLogger,
}: {
  sdk: EditorSDK;
  dependantApps: DependantApps;
  styleParams: StyleParams;
  t: (keyToTranslate: string) => string;
  biLogger: Logger;
}): AppApiModel {
  return {
    isMembersInstalled: () => isMembersAreaInstalled(),
    installMembersAreaAndWishlistPage: (openerCompRef: string) => {
      return doTransaction(sdk, async () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        biLogger.wishlistInstallationStage({stage: 'installMembersAreaAndWishlistPage-init'});
        const progressBarPromise = showProgressBar(sdk, t, true);

        await maybeInstallMembersArea();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        biLogger.wishlistInstallationStage({stage: 'installMembersAreaAndWishlistPage-after-members-install'});
        await dependantApps.tryInstallWishlist();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        biLogger.wishlistInstallationStage({stage: 'installMembersAreaAndWishlistPage-after-wishlist-install'});

        const close = await progressBarPromise;
        await delay(500);
        close();
        await delay(500);
        await enableProductPageWishlist(sdk, openerCompRef);
        return showMembersAreaAddedNotification({
          sdk,
          dependantApps,
          biLogger,
          message: t('settings.productPage.floatingNotification.membersAreaAdded'),
          caption: t('settings.productPage.floatingNotification.previewWishlist'),
        });
      });
    },
    installWishlist: async (openerCompRef: string) => {
      return doTransaction(sdk, async () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        biLogger.wishlistInstallationStage({stage: 'installWishlist-init'});

        const progressBarPromise = showProgressBar(sdk, t, false);

        await dependantApps.tryInstallWishlist();

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        biLogger.wishlistInstallationStage({stage: 'installWishlist-after-wishlist-install'});

        const close = await progressBarPromise;
        await delay(500);
        close();
        await delay(500);

        await enableProductPageWishlist(sdk, openerCompRef);

        return showMembersAreaAddedNotification({
          sdk,
          dependantApps,
          biLogger,
          message: t('settings.productPage.floatingNotification.wishlistAdded'),
          caption: t('settings.productPage.floatingNotification.previewWishlist'),
        });
      });
    },
    uninstallWishlist: () => {
      return doTransaction(sdk, () => dependantApps.uninstallWishlistPageInMembersArea());
    },
    isAppInstalled: (appDefinitionId: string) => {
      return dependantApps.isAppInstalled(appDefinitionId);
    },
    setCheckoutStyleParam(type: StyleParam['type'], key: string, param: {value: any}): Promise<void> {
      return doTransaction(sdk, () => styleParams.setCheckoutStyleParam(type, key, param));
    },
    addShopPage: (title?: string) => {
      return doTransaction(sdk, async () => {
        const allSitePages = await sdk.pages.data.getAll('');
        const ecomPages = allSitePages.filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID);
        const managingAppDefId = ecomPages.some((page) => page.tpaPageId === PageMap.PRODUCT)
          ? ecomAppDefID
          : STORES_APP_DEF_ID;
        return sdk.tpa.add.component('', {
          appDefinitionId: ecomAppDefID,
          managingAppDefId,
          page: {pageId: 'product_gallery', title},
          componentType: 'PAGE',
        });
      });
    },
  };
}

async function showMembersAreaAddedNotification({
  sdk,
  message,
  caption,
  dependantApps,
  biLogger,
}: {
  sdk: EditorSDK;
  message: string;
  caption: string;
  dependantApps: DependantApps;
  biLogger: Logger;
}) {
  const linkClicked = await sdk.editor.showUserActionNotification('', {
    message,
    type: sdk.editor.NotificationType.Success,
    link: {caption},
  });
  linkClicked && navigateToWishlist(sdk, dependantApps, biLogger);
}

function getAssetsUrl() {
  const version = getAppVersion();
  return `https://static.parastorage.com/services/wixstores-client-worker/${version}/assets`;
}

function enableProductPageWishlist(sdk: EditorSDK, productPageCompRef: string) {
  return sdk.tpa.setStyleParams('', {
    compRef: {id: productPageCompRef, type: 'DESKTOP'},
    styleParams: [{type: 'boolean', key: 'productPage_wishlistEnabled', param: {value: true}}],
  });
}

export async function navigateToWishlist(sdk: EditorSDK, dependantApps: DependantApps, biLogger: Logger) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  biLogger.clickToPreviewWishlistInMembersSfe({});
  const wishlistPageRef = await dependantApps.getWishlistPageRef();
  return sdk.document.pages.navigateTo('', {pageRef: wishlistPageRef});
}

const showProgressBar = async (sdk: EditorSDK, t, shouldInstallMembers: boolean = true): Promise<() => void> => {
  let currStep = 1;
  const baseTranslationKey = shouldInstallMembers
    ? 'settings.productPage.loadingModal.addingMembersArea'
    : 'settings.productPage.loadingModal.addingWishlist';
  const progressBarTitle = t(baseTranslationKey);
  const progressBarSubTitles = [
    t(`${baseTranslationKey}.firstSubtitle`),
    t(`${baseTranslationKey}.secondSubtitle`),
    t(`${baseTranslationKey}.thirdSubtitle`),
  ];
  const timeToWaitBetweenSteps = shouldInstallMembers ? 6000 : 3500;

  await sdk.editor.openProgressBar('', {
    title: progressBarTitle,
    totalSteps: 3,
    currentStep: currStep,
    stepTitle: progressBarSubTitles[0],
    image: `${getAssetsUrl()}/images/wishlist.svg`,
  });
  await delay(timeToWaitBetweenSteps);
  await sdk.editor.updateProgressBar('', {
    currentStep: ++currStep,
    stepTitle: progressBarSubTitles[1],
  });
  await delay(timeToWaitBetweenSteps);
  await sdk.editor.updateProgressBar('', {
    currentStep: ++currStep,
    stepTitle: progressBarSubTitles[2],
  });
  await delay(500);
  return () => sdk.editor.closeProgressBar('', {isError: false});
};
